import React, { useState } from "react";
import "../../../style.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginAction } from "../../../store/login-slice";
import { useTable, useSortBy } from "react-table";
import Navbar from "../Navbar";
import Header from "../Header";

const Layout = ({children}) => {

    return (
        <div className="bg-[var(--background-colour)] h-[100vh] w-[100vw] overflow-y-hidden relative">
            {/* Circles */}
            <div className="bg-[--orange-colour] w-[300px] h-[300px] top-[70%] -left-10 absolute rounded-full"></div>
            <div className="bg-[--white-transparent] w-[600px] h-[600px] top-[20%] left-10 absolute rounded-full"></div>
            {/* Blurred overlay */}
            <div className="absolute inset-0 backdrop-blur-[60px] bg-black/40">
                {/* Left and right container */}
                <div className="h-[100vh] w-[100vw] absolute flex flex-row">
                    <div className="container-left hidden md:flex items-center justify-center md:w-[20%]">
                        <Navbar className="hidden md:flex"/>
                    </div>
                    <div className="container-right p-4 pt-0 pb-8 flex flex-col w-full md:flex md:w-[80%]">
                        <Navbar className="flex md:hidden"/>
                        <Header/>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
