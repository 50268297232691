import React, { useState } from 'react';
import '../Dashboard/Dashboard.css';
import Navbar from '../Dashboard/Navbar';
import NavbarTop from '../Dashboard/NavbarTop';
import ConversationList from './ConversationList';
import Conversation from './Conversation';
import UserProfile from './UserProfile';
import './messages.css'
import Layout from '../Dashboard/Layout/Layout';

const Messages = (props) => {
  
const [isSidebarOpen, setSidebarOpen] = useState(false);

const toggleSidebar = () => {
  setSidebarOpen(!isSidebarOpen);
};
  const messagesData = {
    users: ['Alice', 'Bob', 'Charlie', 'David'],
    messages: [
      {
        "id": 1,
        "sender": "Bob",
        "receiver": "Alice",
        "message": "Hey Alice, how are you?",
        "timestamp": "2024-05-12T08:30:00Z"
      },
      {
        "id": 2,
        "sender": "Alice",
        "receiver": "John",
        "message": "Hi John! I'm doing well, thank you. How about you?",
        "timestamp": "2024-05-12T08:35:00Z"
      },
      {
        "id": 3,
        "sender": "John",
        "receiver": "Alice",
        "message": "I'm good too. Just busy with work.",
        "timestamp": "2024-05-12T08:40:00Z"
      },
      {
        "id": 4,
        "sender": "Bob",
        "receiver": "John",
        "message": "Hey John! Are you free for a meeting later today?",
        "timestamp": "2024-05-12T08:45:00Z"
      },
      {
        "id": 5,
        "sender": "John",
        "receiver": "Bob",
        "message": "Sure, I'm available after 2 PM. Let's schedule it.",
        "timestamp": "2024-05-12T08:50:00Z"
      },
      {
        "id": 6,
        "sender": "Michael",
        "receiver": "John",
        "message": "Hi John! Do you have the latest project update?",
        "timestamp": "2024-05-12T08:55:00Z"
      },
      {
        "id": 7,
        "sender": "John",
        "receiver": "Michael",
        "message": "Yes, I'll send it to you shortly.",
        "timestamp": "2024-05-12T09:00:00Z"
      },
      {
        "id": 8,
        "sender": "Emma",
        "receiver": "John",
        "message": "Hello John! Could you please review the document I sent earlier?",
        "timestamp": "2024-05-12T09:05:00Z"
      },
      {
        "id": 9,
        "sender": "John",
        "receiver": "Emma",
        "message": "Sure, I'll take a look at it now.",
        "timestamp": "2024-05-12T09:10:00Z"
      },
      {
        "id": 10,
        "sender": "John",
        "receiver": "Alice",
        "message": "By the way, did you hear about the new project we're starting?",
        "timestamp": "2024-05-12T09:15:00Z"
      },
      {
        "id": 11,
        "sender": "Alice",
        "receiver": "John",
        "message": "No, I haven't. Can you give me some details?",
        "timestamp": "2024-05-12T09:20:00Z"
      },
      {
        "id": 12,
        "sender": "John",
        "receiver": "Alice",
        "message": "Sure, I'll send you an email with the project overview.",
        "timestamp": "2024-05-12T09:25:00Z"
      },
      {
        "id": 13,
        "sender": "Bob",
        "receiver": "John",
        "message": "John, can you please approve the budget for the next quarter?",
        "timestamp": "2024-05-12T09:30:00Z"
      },
      {
        "id": 14,
        "sender": "John",
        "receiver": "Bob",
        "message": "Yes, I'll review it and get back to you by tomorrow.",
        "timestamp": "2024-05-12T09:35:00Z"
      },
      {
        "id": 15,
        "sender": "Michael",
        "receiver": "John",
        "message": "Hi John! Do you have a minute to discuss the project timeline?",
        "timestamp": "2024-05-12T09:40:00Z"
      },
      {
        "id": 16,
        "sender": "John",
        "receiver": "Michael",
        "message": "Sure, let's schedule a meeting for later today.",
        "timestamp": "2024-05-12T09:45:00Z"
      },
      {
        "id": 17,
        "sender": "Emma",
        "receiver": "John",
        "message": "John, can you please share the updated financial report?",
        "timestamp": "2024-05-12T09:50:00Z"
      },
      {
        "id": 18,
        "sender": "John",
        "receiver": "Emma",
        "message": "Yes, I'll send it to you shortly.",
        "timestamp": "2024-05-12T09:55:00Z"
      },
      {
        "id": 19,
        "sender": "John",
        "receiver": "Alice",
        "message": "Alice, could you attend the meeting tomorrow?",
        "timestamp": "2024-05-12T10:00:00Z"
      },
      {
        "id": 20,
        "sender": "Alice",
        "receiver": "John",
        "message": "Yes, I'll be there. What time?",
        "timestamp": "2024-05-12T10:05:00Z"
      }
    ]
  };
  const { users, messages } = messagesData;

  const [selectedUser, setSelectedUser] = useState(null);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  
  return (
      <Layout>
        <div className="message-content">
          <div className="conversation-list-container">
            <ConversationList users={users} onUserSelect={handleUserSelect}/>
          </div>
          <div className="conversation-container">
            {selectedUser && <Conversation user="John" messages={messages} selectedUser={selectedUser}/>}
          </div>
          <div className="user-profile-container">
            {selectedUser && <UserProfile user={selectedUser}/>}
          </div>
        </div>
      </Layout>
  );
};

export default Messages;
