import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Dashboard from './components/Dashboard/Dashboard';
import Invoices from './components/Dashboard/Invoices';
import Products from './components/Dashboard/Products';
import Help from './components/Dashboard/Help';
import Home from './components/Index/Home';
import Signup from './components/Signup/signup';
import Profile from './components/Profile/Profile';
import Messages from './components/Messages/Messages';
import SendMoney from './popUpModal/SendMoney/SendMoney';
import Login from './components/login/login';
import Bank from './components/Bank/Bank';
import ReceiveMoney from './popUpModal/ReceiveMoney/ReceiveMoney';
import TopUp from './popUpModal/TopUp/TopUp';
import Passwordreset from './components/PasswordReset/passwordreset';
import BetaLaunch from './components/BetaLaunch/BetaLaunch';
import Landing from './components/Landing/landing';
import Marketplace from "./components/Dashboard/Marketplace/Marketplace";
import Settings from "./components/Dashboard/Settings";
// import FinalCheckOutElement from './components/SripeCheckout/FinalCheckOutElement';

function App() {
  const isLoggedIn = useSelector(state => state.login.isLoggedIn);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup1" element={<Home />} />
        <Route path="/signup2" element={<Signup />} />
        <Route path="/betasignup" element={<BetaLaunch />} />
        {/*<Route path="/test" element={<FinalCheckOutElement />} />*/}
        {isLoggedIn ? (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/sendmoney" element={<SendMoney />} />
            <Route path="/bankinfo" element={<Bank />} />
            <Route path="/receiveMoney" element={<ReceiveMoney />} />
            <Route path="/topup" element={<TopUp />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/products" element={<Products />} />
            <Route path="/services" element={<Products />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/passwordreset" element={<Passwordreset />} />
            <Route path="/help" element={<Help />} />

          </>
        ) : (
          <Route path="*" element={<Navigate to="/" replace />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
