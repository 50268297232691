import React, { useState } from "react";
import "../../style.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginAction } from "../../store/login-slice";
import { useTable, useSortBy } from "react-table";
import Navbar from "./Navbar";
import Layout from "./Layout/Layout";
import Wallet from "./Wallet";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, Filler } from "chart.js";

ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, Filler);

const Dashboard = (props) => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const portfolio = [
        {
            id: 1,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2"
        },
        {
            id: 2,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2"
        },
        {
            id: 3,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2"
        },
        {
            id: 4,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2"
        },
        {
            id: 5,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2"
        },
        {
            id: 6,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2"
        },
        {
            id: 7,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2"
        },
        {
            id: 8,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2"
        },
        {
            id: 9,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2"
        },
    ];


    const watchlist = [
        {
            id: 1,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2",
            priceHistory: [148, 150, 148, 151, 152, 150, 148]
        },
        {
            id: 2,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2",
            priceHistory: [148, 150, 148, 151, 152, 150, 148]
        },
        {
            id: 3,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2",
            priceHistory: [148, 150, 148, 151, 152, 150, 148]
        },
        {
            id: 4,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2",
            priceHistory: [148, 150, 148, 151, 152, 150, 148]
        },
        {
            id: 5,
            name: "AAPL",
            company: "Apple Inc.",
            shareAmount: "20",
            price: "$148.79",
            change: "0.24 (-0.16%)",
            currentValue: "$2974.2",
            priceHistory: [148, 150, 148, 151, 152, 150, 148]
        },
    ];

    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const handleNavbarClick = () => {
        setSidebarOpen(!isSidebarOpen);
    };
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const handleLogout = () => {
        dispatch(loginAction.userLogout());
    };

    // Dummy data for the line chart
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "white", // Keep the tick labels white
                },
                grid: {
                    display: false, // Remove grid lines
                    drawBorder: false,
                },
            },
            y: {
                ticks: {
                    color: "white", // Keep the tick labels white
                },
                grid: {
                    display: false, // Remove grid lines
                    drawBorder: false,
                },
            },
        },
    };

    const optionsMiniGraphs = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
        },
        scales: {
            x: {
                ticks: {
                    display: false,
                },
                grid: {
                    display: false, // Remove grid lines
                    drawBorder: false,
                },
            },
            y: {
                ticks: {
                    display: false,
                },
                grid: {
                    display: false, // Remove grid lines
                    drawBorder: false,
                },
            },
        },
    };

    // Dataset configuration
    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Returns",
                data: [100, 200, 150, 300, 250, 400, 300, 300, 150, 300, 250, 400],
                borderColor: "rgba(255, 255, 255, 1)", // Line colour
                backgroundColor: "rgba(255, 255, 255, 0.2)", // Area under the line
                tension: 0.4, // Smooth curve
                fill: true, // Enable area under the line
            },
        ],
    };

    return (
        <Layout>
            <div className="overflow-y-scroll flex flex-col justify-between h-full pt-14">
                {/* Current balance and returns graph */}
                <div className="flex flex-col md:flex-row justify-between">
                    {/* Current balance */}
                    <div className="bg-[--card-dashboard-colour] w-full md:w-[34%] h-[400px] rounded-[20px]">
                        <Wallet/>
                    </div>
                    {/* Returns graph */}
                    <div
                        className="bg-[--card-dashboard-colour] w-full mt-4 md:mt-0 md:w-[64%] h-[400px] rounded-[20px] relative">
                        {/* Circles */}
                        <div
                            className="bg-[--green-colour] w-[300px] h-[300px] top-10 right-0 absolute rounded-full"></div>
                        <div
                            className="bg-[--orange-colour] w-[200px] h-[200px] top-[50%] left-40 absolute rounded-full"></div>
                        {/* Blurred overlay */}
                        <div
                            className="absolute p-8 flex flex-col justify-between inset-0 w-[100%] backdrop-blur-[80px] rounded-[20px] bg-[var(--background-colour)]/40">
                            <p className="text-[var(--label-gray-colour)]">Portfolio returns</p>
                            <Line data={data} options={options} className="!w-[100%] !h-[90%]"/>
                        </div>
                    </div>
                </div>
                {/* Portfolio and watchlist */}
                <div className="flex flex-col md:flex-row justify-between mt-6">
                    {/* Portfolio */}
                    <div className="w-full mt-4 md:mt-0 md:w-[64%] h-full">
                        <div className="bg-[--card-dashboard-colour] w-full h-full rounded-[20px] p-8">
                            <p className="text-[var(--label-gray-colour)] mb-8">My Portfolio</p>
                            <div
                                className="overflow-y-auto w-full max-h-[calc(88vh-180px)]"> {/* Use max-height here */}
                                {portfolio.map((stock) => (
                                    <div key={stock.id}
                                         className="text-white flex flex-row justify-between items-center mb-4">
                                        <div>
                                            <p className="text-[20px]">{stock.name}</p>
                                            <p className="text-[12px]">{stock.company}</p>
                                        </div>
                                        <div>
                                            <p className="text-[var(--label-gray-colour)] text-[13px]">Share amount</p>
                                            <p>{stock.shareAmount}</p>
                                        </div>
                                        <div>
                                            <p className="text-[var(--label-gray-colour)] text-[13px]">Price</p>
                                            <p>{stock.price}</p>
                                        </div>
                                        <div>
                                            <p className="text-[var(--label-gray-colour)] text-[13px]">Change</p>
                                            <p className="text-red-600">{stock.change}</p>
                                        </div>
                                        <div>
                                            <p className="text-[var(--label-gray-colour)] text-[13px]">Current value</p>
                                            <p>{stock.currentValue}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* Watchlist */}
                    <div className="w-full mt-4 md:mt-0 md:w-[34%] h-full">
                        <div className="bg-[--card-dashboard-colour] w-full h-full rounded-[20px] p-8">
                            <p className="text-[var(--label-gray-colour)] mb-8">My Watchlist</p>
                            <div
                                className="overflow-y-auto w-full max-h-[calc(88vh-180px)]"> {/* Use max-height here */}
                                {watchlist.map((stock) => (
                                    <div key={stock.id}
                                         className="text-white flex flex-row justify-between items-center mb-4">
                                        <div>
                                            <p className="text-[20px]">{stock.name}</p>
                                            <p className="text-[12px]">{stock.company}</p>
                                        </div>
                                        <div>
                                            <Line
                                                data={{
                                                    datasets: [
                                                        {
                                                            data: stock.priceHistory, // Price history data for the mini graph
                                                            borderColor: "rgba(255, 255, 255, 1)", // Line colour
                                                            backgroundColor: "rgba(255, 255, 255, 0.2)", // Fill under the line
                                                            tension: 0.1, // Smooth curve
                                                            fill: true, // Enable filling the area under the line
                                                        },
                                                    ],
                                                }}
                                                options={optionsMiniGraphs}
                                                className="!w-[80px] !h-[50px]" // Small size for the mini graph
                                            />
                                        </div>
                                        <div className="flex flex-col items-end">
                                            <p className="text-white text-[15px]">{stock.price}</p>
                                            <p className="text-red-600 text-[13px]">{stock.change}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;
