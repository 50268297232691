import React, { useState, useEffect } from 'react';
import axios from 'axios';
import topup from "../../assets/topup.png";
import send from "../../assets/send.png";
import request from "../../assets/request.png";
import history from "../../assets/history.png";
import TopupModal from "../../popUpModal/TopUp/TopUp"
import ReceiveMoneyModal from "../../popUpModal/ReceiveMoney/ReceiveMoney"
import SendMoneyModal from "../../popUpModal/SendMoney/SendMoney"
const Wallet = (props) => {
    const [modalType, setModalType] = useState(null);
    const [totalBalance, setTotalBalance] = useState(0); // Initial balance
    /*
        useEffect(() => {
            const fetchBalance = async () => {
                try {
                    const resp = await fetch("https://skenderpay.xyz:8443/getWalletAdd",
                    {
                        method: "POST",
                        body: JSON.stringify({ userId : 1})
                    }
                    );
                    console.log(resp)
                    const newBalance = resp.data.balance;
                    setTotalBalance(newBalance);
                } catch (error) {
                    console.error('Error fetching balance:', error);
                }
            };

            fetchBalance();
        }, []); */
    const handleModalOpen = (type) => {
        setModalType(type);
    };

    const handleCloseModal = () => {
        setModalType("");
    };
    return (
        <div className="p-8 text-[var(--label-gray-colour)] h-full flex flex-col justify-between">
            <div className="flex-grow">
                <p className="mb-2">Current value</p>
                <p className="text-white text-[60px]"><span className="mr-2 text-[30px]">$</span>{totalBalance}</p>
                <div className="text-[var(--green-colour)] flex flex-row items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"
                         fill="currentColor">
                        <path d="M12 8L18 14H6L12 8Z"></path>
                    </svg>
                    <p className="text-[var(--green-colour)] text-[20px]">0.61 (+1.32%)</p>
                </div>
                <div className="flex flex-row gap-3 w-full">
                    <button onClick={() => handleModalOpen('topup')}
                            className="button bg-[var(--white-transparent)] px-6 py-2 flex flex-row items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" className="mr-1"
                             fill="currentColor">
                            <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
                        </svg>
                        Top Up
                    </button>
                    <button onClick={() => handleModalOpen('send')}
                            className="button bg-[var(--white-transparent)] px-6 py-2 flex flex-row items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" className="mr-1"
                             fill="currentColor">
                            <path
                                d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path>
                        </svg>
                        Send
                    </button>
                    <button onClick={() => handleModalOpen('request')}
                            className="button bg-[var(--white-transparent)] px-6 py-2 flex flex-row items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" className="mr-1"
                             fill="currentColor">
                            <path
                                d="M9 13.589L17.6066 4.98242L19.0208 6.39664L10.4142 15.0032H18V17.0032H7V6.00324H9V13.589Z"></path>
                        </svg>
                        Request
                    </button>
                </div>
                {modalType === 'topup' && <TopupModal onClose={handleCloseModal}/>}
                {modalType === 'send' && <SendMoneyModal onClose={handleCloseModal}/>}
                {modalType === 'request' && <ReceiveMoneyModal onClose={handleCloseModal} />}
            </div>
            {/* Invested value */}
            <div className="flex flex-col">
                <p className="">Invested value</p>
                <div className="flex flex-row justify-between items-center">
                    <div>
                        <p className="text-white text-[40px]"><span className="mr-2 text-[20px]">$</span>{totalBalance}
                        </p>
                    </div>
                    <div className="text-[var(--green-colour)] flex flex-row items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                             fill="currentColor">
                            <path d="M12 8L18 14H6L12 8Z"></path>
                        </svg>
                        <p className="text-[var(--green-colour)] text-[15px]">0.61 (+1.32%)</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Wallet