import React, { useState } from 'react';
import '../../style.css';
import Navbar from './Navbar';
import NavbarTop from './NavbarTop';
import { useTable, useSortBy } from 'react-table';
import Layout from "./Layout/Layout";

const transactions = [
  {
    productName: 'Product 1',
    period: '3 Months',
    type: 'Promotion',
    price: '$835.00',
    status: 'Success'
  },
  {
    productName: 'Product 2',
    period: '6 Months',
    type: 'Full Service',
    price: '$835.00',
    status: 'Success'
  },
  {
    productName: 'Product 3',
    period: '15 Days',
    type: 'Promotion',
    price: '$150.00',
    status: 'Pending'
  },
  {
    productName: 'Product 4',
    period: 'Lifetime',
    type: 'Subscription',
    price: '$200.00',
    status: 'Success'
  }
];

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  return (
    <table {...getTableProps()} className="product-list">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} className="product-item">
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={
                  column.isSorted
                    ? column.isSortedDesc
                      ? 'sort-desc'
                      : 'sort-asc'
                    : ''
                }
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="product-item">
              {row.cells.map(cell => {
                // Add specific classes based on status
                let statusClass = '';
                if (cell.column.id === 'status') {
                  statusClass = cell.value === 'Pending' ? 'product-pending' : 'product-success';
                }
                return (
                  <td {...cell.getCellProps()} className={`product-name ${statusClass}`}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const Products = (props) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Product Name',
        accessor: 'productName'
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Period',
        accessor: 'period'
      },
      {
        Header: 'Price',
        accessor: 'price'
      },
      {
        Header: 'Status',
        accessor: 'status'
      }
    ],
    []
  );

  return (
      <Layout>
          <div className="product-invoices">
              <h2>Recent Products</h2>
              <Table columns={columns} data={transactions}/>
          </div>
      </Layout>
  );
};

export default Products;
