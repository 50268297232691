import React, { useState } from 'react';
import '../../style.css';
import search from '../../assets/search.png';
import dropdownside from '../../assets/sideway.png';
import Navbar from './Navbar';
import NavbarTop from './NavbarTop';
import { useTable, useSortBy } from 'react-table';
import Layout from './Layout/Layout';

const Settings = (props) => {
    return (
        <Layout>
            <h1>Settings</h1>
        </Layout>
    );
};

export default Settings;
