import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import module from "./style.module.css";

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isTransparent, setIsTransparent] = useState(true);

    // Handle scrolling behaviour to keep header transparent
    useEffect(() => {
        const handleScroll = () => {
            if (!isMobileMenuOpen) {
                // Header should be transparent when at the top of the page
                setIsTransparent(window.scrollY <= 0);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [isMobileMenuOpen]);

    // Toggle the mobile menu and adjust header transparency
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);

        if (!isMobileMenuOpen) {
            // Force header to be white when menu is open
            setIsTransparent(false);
        } else {
            // Revert to transparent after menu closes
            setIsTransparent(window.scrollY <= 0);
        }
    };

    const navigate = useNavigate();

    const goToLoginPage = () => {
        navigate('/login');
    };

    return (
        <header
            id="header"
            className={`fixed top-0 left-0 w-full px-4 md:px-0 flex justify-center ${
                isTransparent ? "bg-transparent" : "backdrop-blur-[60px] bg-white/0"
            } z-50 transition-all duration-300 ease-in-out`}
        >
            <div
                className={`flex justify-between items-center pt-5 pb-5 w-full ${module.maxWContainer}`}
            >
                {/* Logo */}
                <a href="#" className="logo-container">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="22"
                        viewBox="0 0 158 140"
                        fill="none"
                    >
                        <path
                            d="M71.0001 0H69.6043L9.10434 61C-19.3957 88.5 24.6043 145 64.1043 108L117.604 54.5C122.104 50 126.604 55 123.604 58L42.1045 139.5H86.5001H88.0001L148.5 78.5C177 51 133 -5.5 93.5001 31.5L40 85C35.5001 89.5 31.0001 84.5 34 81.5L115.5 0H71.0001Z"
                            fill="white"
                        />
                    </svg>
                </a>

                {/* Desktop Menu */}
                <div className="hidden md:flex gap-8">
                    {["Section", "Section", "Section", "Section", "Section"].map(
                        (section, index) => (
                            <a
                                key={index}
                                href="#"
                                className={`menu-link ${
                                    isTransparent ? "text-white" : "text-white"
                                } transition-colors duration-300`}
                            >
                                {section}
                            </a>
                        )
                    )}
                </div>

                <button onClick={goToLoginPage} className={`${module.button} bg-[--white-transparent] px-8 py-2 text-[12px] hidden md:block`}>Login</button>

                {/* Mobile Menu Button */}
                <button
                    id="menu-button"
                    onClick={toggleMobileMenu}
                    className={`${
                        module.hamburger
                    } md:hidden flex flex-col justify-between h-10 z-50`}
                >
                    <span className={isTransparent ? "bg-white" : "bg-black"}></span>
                    <span className={isTransparent ? "bg-white" : "bg-black"}></span>
                    <span className={isTransparent ? "bg-white" : "bg-black"}></span>
                </button>
            </div>

            {/* Mobile Menu */}
            <div
                id="mobile-menu"
                className={`fixed top-0 left-0 w-full h-screen bg-white px-4 md:px-0 flex flex-col text-black z-40 transition-all duration-500 ease-in-out ${
                    isMobileMenuOpen
                        ? "opacity-100 translate-y-0 visible"
                        : "opacity-0 -translate-y-full invisible"
                }`}
            >
                <div className="mt-40">
                    <ul className="space-y-6 text-2xl font-medium">
                        {["Section", "Section", "Section", "Section", "Section"].map(
                            (section, index) => (
                                <li
                                    key={index}
                                    className="flex flex-row items-center justify-between"
                                >
                                    <a href="#" className="hover:text-gray-500">
                                        {section}
                                    </a>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="32"
                                        height="32"
                                        fill="rgba(0,0,0,1)"
                                    >
                                        <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                                    </svg>
                                </li>
                            )
                        )}
                    </ul>
                    <a href="">
                        <button className="mt-14 w-full main-button bg-black text-white">
                            Login
                        </button>
                    </a>
                </div>

                <div className="absolute bottom-10 flex gap-6 text-gray-500">
                    <a href="#" className="hover:text-black">
                        Facebook
                    </a>
                    <a href="#" className="hover:text-black">
                        Instagram
                    </a>
                    <a href="#" className="hover:text-black">
                        Twitter
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header;