import React from 'react';
import Navbar from './Navbar';
import NavbarTop from './NavbarTop';
import { useState } from 'react';
import Layout from './Layout/Layout';

const Help = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
      <Layout>
        <div className="help-content">
          <h2>Contact Support</h2>
          <p>If you have any questions, please contact us at:</p>
          <p><strong>xyz@email</strong></p>
        </div>
      </Layout>
  );
};

export default Help;
