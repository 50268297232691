import React, {useEffect, useRef, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loginAction} from "../../store/login-slice";
import bell from "../../assets/bell.png";
import reddot from "../../assets/redDot.png";
import search from "../../assets/search.png";
import dropdown from "../../assets/dropdown.png";

const Header = () => {
    const location = useLocation(); // React Router hook to get the current path

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const dropdownRef = useRef(null);
    const notificationRef = useRef(null);
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const username = useSelector((state) => state.login.firstName);

    const notifications = [
        {
            notification_id: 1,
            user_id: 3,
            notification_timestamp: '2024-09-08 12:00:00',
            notification_content: 'Your recent transaction has been successfully processed.',
        },
        {
            notification_id: 2,
            user_id: 3,
            notification_timestamp: '2024-09-08 14:30:00',
            notification_content: 'A new login was detected from an unknown device. Please verify if this was you.',
        },
        {
            notification_id: 3,
            user_id: 3,
            notification_timestamp: '2024-09-08 15:45:00',
            notification_content: 'Your password has been changed successfully.',
        },
        {
            notification_id: 4,
            user_id: 3,
            notification_timestamp: '2024-09-08 17:00:00',
            notification_content: 'Reminder: Your subscription is due for renewal in 3 days.',
        },
        {
            notification_id: 5,
            user_id: 3,
            notification_timestamp: '2024-09-08 18:15:00',
            notification_content: 'You have a new message from customer support.',
        },
    ];

    const userNotifications = notifications.filter(notification => notification.user_id === 3);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleNotificationPanel = () => {
        setIsNotificationOpen(!isNotificationOpen);
    };

    const handleLogout = () => {
        dispatch(loginAction.userLogout());
    };

    // Close dropdown/notification panel if clicked outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setIsNotificationOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, notificationRef]);

    const currentDate = new Date();
    const options = { weekday: "long", month: "short", day: "numeric", year: "numeric" };
    const formattedDate = currentDate.toLocaleDateString("en-US", options);

    return (
        <div className="w-full text-white flex flex-row justify-between pt-8">
            {/* Date */}
            <p className="text-white text-[20px]">{formattedDate}</p>
            {/* Search */}
            {/*<input className="input !w-[30%] !px-8 !py-3" placeholder="Search symbols or any stocks"/>*/}
            {/* Actions */}
            <div className="flex flex-row items-center -mt-2">
                <button className="button !border-none !m-0 transition" ref={notificationRef} onClick={toggleNotificationPanel}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                         fill="currentColor">
                        <path
                            d="M20 17H22V19H2V17H4V10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10V17ZM9 21H15V23H9V21Z"></path>
                    </svg>
                </button>


                <div className="flex flew-row ml-5 hover:bg-[var(--white-transparent)] px-4 py-2 cursor-pointer rounded-[20px]"
                     ref={dropdownRef} onClick={toggleDropdown}>
                    <div className="frame9-home-v2-frame5">
                      <span className="frame9-home-v2-text002 bodylargeextrabold">
                        <span>{username}</span>
                      </span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                         fill="currentColor">
                        <path
                            d="M11.9999 13.1714L16.9497 8.22168L18.3639 9.63589L11.9999 15.9999L5.63599 9.63589L7.0502 8.22168L11.9999 13.1714Z"></path>
                    </svg>
                    {isDropdownOpen && (
                        <div
                            className="backdrop-blur-[100px] bg-[var(--white-transparent)]/40 w-[300px] fixed top-40 md:top-20 flex flex-col items-start right-4 rounded-[20px] p-8 z-50">
                            <h4>{username}</h4>
                            <h6>Business Account</h6>
                            <button className="button px-4 py-1">Your Details</button>
                            <button className="button px-4 py-1">Account Settings</button>
                            <button className="button px-4 py-1" onClick={handleLogout}>Logout</button>
                        </div>
                    )}
                </div>
                {isNotificationOpen && (
                    <div
                        className="backdrop-blur-[80px] bg-[var(--white-transparent)]/40 md:w-[500px] flex flex-col gap-4 fixed top-40 w-auto md:top-20 right-4 rounded-[20px] left-4 md:left-auto p-8 z-50"
                        ref={notificationRef}>
                        <h2 className="text-[20px]">Notifications</h2>
                        {userNotifications.map((notification) => (
                            <div key={notification.notification_id} className="bg-[var(--white-transparent)] hover:bg-[var(--white-transparent-semi)] cursor-pointer transition rounded-[15px] p-4">
                                <p>{notification.notification_timestamp}</p>
                                <p>{notification.notification_content}</p>
                            </div>
                        ))}
                    </div>
                )}

            </div>
        </div>
    );
};

export default Header;