import logo from "../../assets/Logo.png";
import middleImg from "../../assets/MiddleImg.png";
import React, { useState, useEffect } from "react";
import module from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAction } from "../../store/login-slice";
import { Eye, EyeOff } from "lucide-react";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [shake, setShake] = useState(false);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    // Mount animation
    useEffect(() => {
        setMounted(true);
    }, []);

    // Handle touch events for swipe
    const handleTouchStart = (e) => {
        setTouchStart(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;

        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > 50;
        const isRightSwipe = distance < -50;

        if (isLeftSwipe) {
            navigate("/signup1");
        } else if (isRightSwipe) {
            // Optional: Add right swipe action
        }

        setTouchStart(null);
        setTouchEnd(null);
    };

    const validateEmail = (input) => {
        const validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return input.match(validRegex);
    };

    const validatePassword = (input) => {
        return (
            input.length >= 8 &&
            /[a-z]/.test(input) &&
            /[A-Z]/.test(input) &&
            /[0-9]/.test(input)
        );
    };

    const [formData, setFormData] = useState({
        email: "",
        password: "",
        emailTouched: false,
        passwordTouched: false,
    });

    const emailIsValid = validateEmail(formData.email);
    const passwordIsValid = validatePassword(formData.password);
    const formIsValid = emailIsValid && passwordIsValid;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleBlur = (field) => {
        setFormData((prev) => ({
            ...prev,
            [`${field}Touched`]: true,
        }));
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if (!formIsValid) {
            setShake(true);
            setTimeout(() => setShake(false), 500);
            return;
        }

        setIsLoading(true);
        try {
            const resp = await validatedUser({
                email: formData.email,
                password: formData.password,
            });

            if (resp?.message === "success") {
                setError(false);
                dispatch(
                    loginAction.userLogin({
                        userId: resp.userId,
                        firstName: resp.firstName,
                    })
                );
                // Add fade-out animation before navigation
                setMounted(false);
                setTimeout(() => {
                    navigate("/dashboard");
                }, 300);
            } else {
                setError(true);
                setShake(true);
                setTimeout(() => setShake(false), 500);
            }
        } catch (err) {
            setError(true);
            setShake(true);
            setTimeout(() => setShake(false), 500);
        } finally {
            setIsLoading(false);
        }
    };

    async function validatedUser(data) {
        const resp = await fetch("https://skenderpay.xyz:8443/check", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const parsedResp = await resp.json();
        return {
            message: parsedResp.message,
            userId: parsedResp.user_id,
            firstName: parsedResp.FirstName,
        };
    }

    // Dynamic classes for animations
    const containerClasses = `${module.innerContainer} ${
        mounted ? module.fadeIn : module.fadeOut
    } ${shake ? module.shake : ""}`;

    return (
        <div className="bg-[var(--background-colour)] h-[100vh] w-[100vw] relative">
            {/* Circles */}
            <div className="bg-[--green-colour] w-[700px] h-[700px] top-40 right-0 absolute rounded-full"></div>
            <div className="bg-[--orange-colour] w-[400px] h-[400px] top-[60%] left-10 absolute rounded-full"></div>
            <div className="bg-[--white-transparent] w-[600px] h-[600px] top-[20%] left-10 absolute rounded-full"></div>
            {/* Blurred overlay */}
            <div className="absolute inset-0 backdrop-blur-[60px] bg-black/40 flex justify-center items-center"></div>
            {/* Left and right container */}
            <div className="h-[100vh] w-[100vw] absolute flex flex-row">
                <div className="container-left hidden md:flex items-center justify-center md:w-[50%]">
                    <div className="max-w-[80%]">
                        {/* Logo */}
                        <div className="flex flex-row items-center mb-8">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="62" viewBox="0 0 158 140"
                                 fill="none">
                                <path
                                    d="M71.0001 0H69.6043L9.10434 61C-19.3957 88.5 24.6043 145 64.1043 108L117.604 54.5C122.104 50 126.604 55 123.604 58L42.1045 139.5H86.5001H88.0001L148.5 78.5C177 51 133 -5.5 93.5001 31.5L40 85C35.5001 89.5 31.0001 84.5 34 81.5L115.5 0H71.0001Z"
                                    fill="white"/>
                            </svg>
                            <p className="text-white ml-3 text-[28px]">SkenderPay</p>
                        </div>
                        <h1 className="text-white text-[60px] leading-[1.2]">Digital payments.<br/> <span className="font-['Tangerine'] font-[400] text-[120px]">Simplified.</span></h1>
                        <p className="text-white text-[20px] mt-8">Our innovative solution promises to revolutionize
                            transactions offering unparalleled security, efficiency, and transparency.</p>
                    </div>
                </div>
                <div className="container-right p-10 lg:p-0 flex flex-col w-full md:flex md:w-[50%] flex items-center justify-center">
                    <div className="flex flex-row lg:hidden items-center mb-8">
                        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="62" viewBox="0 0 158 140"
                             fill="none">
                            <path
                                d="M71.0001 0H69.6043L9.10434 61C-19.3957 88.5 24.6043 145 64.1043 108L117.604 54.5C122.104 50 126.604 55 123.604 58L42.1045 139.5H86.5001H88.0001L148.5 78.5C177 51 133 -5.5 93.5001 31.5L40 85C35.5001 89.5 31.0001 84.5 34 81.5L115.5 0H71.0001Z"
                                fill="white"/>
                        </svg>
                        <p className="text-white ml-3 text-[28px]">SkenderPay</p>
                    </div>
                    <form onSubmit={onSubmitHandler}
                          className="bg-[--white-transparent-full] p-7 w-full max-w-2xl rounded-[20px]">
                        <h1 className="text-[30px] text-white font-semibold mb-1">Welcome back</h1>
                        <p className="text-white text-[13px] mb-6">Access your account and explore endless
                            possibilities.</p>
                        <div className="flex flex-col mt-3">
                            <label className={`${module.labelInput} mb-1`} htmlFor="email">Email Address</label>
                            <input type="email" id="email" name="email"
                                   className={`${module.input} ${
                                       formData.emailTouched && !emailIsValid
                                           ? module.invalid
                                           : ""
                                   }`} onChange={handleInputChange} onBlur={() => handleBlur("email")}
                                   value={formData.email} placeholder="Enter your email"/>
                            {formData.emailTouched && !emailIsValid && (
                                <p className={`${module.fieldError} ${module.fadeIn}`}>
                                    Please enter a valid email address
                                </p>
                            )}
                        </div>
                        <div className="flex flex-col mt-3">
                            <label className={`${module.labelInput} mb-1`} htmlFor="password">Password</label>
                            <div className={module.passwordWrapper}>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    className={`${module.input} w-full ${
                                        formData.passwordTouched && !passwordIsValid
                                            ? module.invalid
                                            : ""
                                    }`}
                                    onChange={handleInputChange}
                                    onBlur={() => handleBlur("password")}
                                    value={formData.password}
                                    placeholder="Enter your password"
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    className={module.passwordToggle}
                                >
                                    {showPassword ? <EyeOff size={20}/> : <Eye size={20}/>}
                                </button>
                            </div>
                            {formData.passwordTouched && !passwordIsValid && (
                                <p className={`${module.fieldError} ${module.fadeIn}`}>
                                    Password must be at least 8 characters and include
                                    uppercase, lowercase, and numbers
                                </p>
                            )}
                        </div>
                        <div className="flex flex-col items-center mt-3">
                            <button type="submit" className={`${module.button} w-full bg-[--white-transparent] ${
                                !formIsValid || isLoading ? module.buttonDisabled : ""
                            } ${mounted ? module.fadeInUp : ""}`} disabled={!formIsValid || isLoading}>
                                {isLoading ? "Signing in..." : "Login"}
                            </button>
                            {error && (
                                <div
                                    className={`${module.invalidUser} ${module.errorText} ${module.fadeIn}`}
                                >
                                    <p className="mt-4 text-red-600">Either your username or password is incorrect</p>
                                </div>
                            )}
                        </div>
                        <div className={`flex flex-row justify-between mt-5 ${
                            mounted ? module.fadeInUp : ""
                        }`}>
                            <button type="button" className={module.label} onClick={() => navigate("/signup1")}>
                                Not a user? Log in
                            </button>
                            <button type="button" className={module.label} onClick={() => navigate("/passwordreset")}>
                                Forgot Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* Circle */}
        </div>
    );
};

export default Login;
