import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";
import home from "../../assets/Home.png";
import invoice from "../../assets/Invoice.png";
import products from "../../assets/products.png";
import activity from "../../assets/activity.png";
import help from "../../assets/help.png";
import setting from "../../assets/setting.png";
import message from "../../assets/message.png";
import "./Dashboard.css";

const navHeader = [
    {
        to: "/dashboard",
        imgSrc: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6" fill="currentColor">
                <path d="M3 12C3 12.5523 3.44772 13 4 13H10C10.5523 13 11 12.5523 11 12V4C11 3.44772 10.5523 3 10 3H4C3.44772 3 3 3.44772 3 4V12ZM3 20C3 20.5523 3.44772 21 4 21H10C10.5523 21 11 20.5523 11 20V16C11 15.4477 10.5523 15 10 15H4C3.44772 15 3 15.4477 3 16V20ZM13 20C13 20.5523 13.4477 21 14 21H20C20.5523 21 21 20.5523 21 20V12C21 11.4477 20.5523 11 20 11H14C13.4477 11 13 11.4477 13 12V20ZM14 3C13.4477 3 13 3.44772 13 4V8C13 8.55228 13.4477 9 14 9H20C20.5523 9 21 8.55228 21 8V4C21 3.44772 20.5523 3 20 3H14Z"></path>
            </svg>
        ),
        text: "My Wallet"
    },
    {
        to: "/marketplace",
        imgSrc: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6" fill="currentColor">
                <path d="M20.0833 10.4999L21.2854 11.2212C21.5221 11.3633 21.5989 11.6704 21.4569 11.9072C21.4146 11.9776 21.3557 12.0365 21.2854 12.0787L11.9999 17.6499L2.71451 12.0787C2.47772 11.9366 2.40093 11.6295 2.54301 11.3927C2.58523 11.3223 2.64413 11.2634 2.71451 11.2212L3.9166 10.4999L11.9999 15.3499L20.0833 10.4999ZM20.0833 15.1999L21.2854 15.9212C21.5221 16.0633 21.5989 16.3704 21.4569 16.6072C21.4146 16.6776 21.3557 16.7365 21.2854 16.7787L12.5144 22.0412C12.1977 22.2313 11.8021 22.2313 11.4854 22.0412L2.71451 16.7787C2.47772 16.6366 2.40093 16.3295 2.54301 16.0927C2.58523 16.0223 2.64413 15.9634 2.71451 15.9212L3.9166 15.1999L11.9999 20.0499L20.0833 15.1999ZM12.5144 1.30864L21.2854 6.5712C21.5221 6.71327 21.5989 7.0204 21.4569 7.25719C21.4146 7.32757 21.3557 7.38647 21.2854 7.42869L11.9999 12.9999L2.71451 7.42869C2.47772 7.28662 2.40093 6.97949 2.54301 6.7427C2.58523 6.67232 2.64413 6.61343 2.71451 6.5712L11.4854 1.30864C11.8021 1.11864 12.1977 1.11864 12.5144 1.30864Z"></path>
            </svg>
        ),
        text: "Marketplace"
    },
    {
        to: "/invoices",
        imgSrc: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6" fill="currentColor">
                <path
                    d="M3 8L9.00319 2H19.9978C20.5513 2 21 2.45531 21 2.9918V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5501 3 20.9932V8ZM10 3.5L4.5 9H10V3.5Z"></path>
            </svg>
        ),
        text: "Invoices"
    },
    {
        to: "/products",
        imgSrc: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6" fill="currentColor">
                <path
                    d="M16 16C17.6569 16 19 17.3431 19 19C19 20.6569 17.6569 22 16 22C14.3431 22 13 20.6569 13 19C13 17.3431 14.3431 16 16 16ZM6 12C8.20914 12 10 13.7909 10 16C10 18.2091 8.20914 20 6 20C3.79086 20 2 18.2091 2 16C2 13.7909 3.79086 12 6 12ZM16 18C15.4477 18 15 18.4477 15 19C15 19.5523 15.4477 20 16 20C16.5523 20 17 19.5523 17 19C17 18.4477 16.5523 18 16 18ZM6 14C4.89543 14 4 14.8954 4 16C4 17.1046 4.89543 18 6 18C7.10457 18 8 17.1046 8 16C8 14.8954 7.10457 14 6 14ZM14.5 2C17.5376 2 20 4.46243 20 7.5C20 10.5376 17.5376 13 14.5 13C11.4624 13 9 10.5376 9 7.5C9 4.46243 11.4624 2 14.5 2ZM14.5 4C12.567 4 11 5.567 11 7.5C11 9.433 12.567 11 14.5 11C16.433 11 18 9.433 18 7.5C18 5.567 16.433 4 14.5 4Z"></path>
            </svg>
        ),
        text: "Products"
    },
    {
        to: "/services",
        imgSrc: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6" fill="currentColor">
                <path d="M3 12H7V21H3V12ZM17 8H21V21H17V8ZM10 2H14V21H10V2Z"></path>
            </svg>
        ),
        text: "Services"
    },
    {
        to: "/messages",
        imgSrc: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6" fill="currentColor">
                <path d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM12.0606 11.6829L5.64722 6.2377L4.35278 7.7623L12.0731 14.3171L19.6544 7.75616L18.3456 6.24384L12.0606 11.6829Z"></path>
            </svg>
        ),
        text: "Messages"
    },
];

const navFooter = [
    {
        to: "/help",
        imgSrc: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6" fill="currentColor">
                <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z"></path>
            </svg>
        ),
        text: "Get Help"
    },
    {
        to: "/settings",
        imgSrc: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6" fill="currentColor">
                <path d="M9.95401 2.2106C11.2876 1.93144 12.6807 1.92263 14.0449 2.20785C14.2219 3.3674 14.9048 4.43892 15.9997 5.07103C17.0945 5.70313 18.364 5.75884 19.4566 5.3323C20.3858 6.37118 21.0747 7.58203 21.4997 8.87652C20.5852 9.60958 19.9997 10.736 19.9997 11.9992C19.9997 13.2632 20.5859 14.3902 21.5013 15.1232C21.29 15.7636 21.0104 16.3922 20.6599 16.9992C20.3094 17.6063 19.9049 18.1627 19.4559 18.6659C18.3634 18.2396 17.0943 18.2955 15.9997 18.9274C14.9057 19.559 14.223 20.6294 14.0453 21.7879C12.7118 22.067 11.3187 22.0758 9.95443 21.7906C9.77748 20.6311 9.09451 19.5595 7.99967 18.9274C6.90484 18.2953 5.63539 18.2396 4.54272 18.6662C3.61357 17.6273 2.92466 16.4164 2.49964 15.1219C3.41412 14.3889 3.99968 13.2624 3.99968 11.9992C3.99968 10.7353 3.41344 9.60827 2.49805 8.87524C2.70933 8.23482 2.98894 7.60629 3.33942 6.99923C3.68991 6.39217 4.09443 5.83576 4.54341 5.33257C5.63593 5.75881 6.90507 5.703 7.99967 5.07103C9.09364 4.43942 9.7764 3.3691 9.95401 2.2106ZM11.9997 14.9992C13.6565 14.9992 14.9997 13.6561 14.9997 11.9992C14.9997 10.3424 13.6565 8.99923 11.9997 8.99923C10.3428 8.99923 8.99967 10.3424 8.99967 11.9992C8.99967 13.6561 10.3428 14.9992 11.9997 14.9992Z"></path>
            </svg>
        ),
        text: "Settings"
    },
];

const Sidebar = ({className}) => {
    const location = useLocation(); // React Router hook to get the current path
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div className={`${className} w-[100%]`}>
            {/* Mobile Menu Toggle Button */}
            <div className="md:hidden flex items-center justify-between text-white mt-4 w-[100%]">
                <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="24" viewBox="0 0 158 140" fill="none">
                        <path
                            d="M71.0001 0H69.6043L9.10434 61C-19.3957 88.5 24.6043 145 64.1043 108L117.604 54.5C122.104 50 126.604 55 123.604 58L42.1045 139.5H86.5001H88.0001L148.5 78.5C177 51 133 -5.5 93.5001 31.5L40 85C35.5001 89.5 31.0001 84.5 34 81.5L115.5 0H71.0001Z"
                            fill="white"
                        />
                    </svg>
                    <p className="ml-2 text-[18px]">SkenderPay</p>
                </div>
                <button
                    className="button !border-none !mt-0 text-white focus:outline-none"
                    onClick={toggleMobileMenu}
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d={isMobileMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                        />
                    </svg>
                </button>
            </div>

            {/* Sidebar */}
            <div
                className={`h-[100vh] w-[100vw] md:w-[100%] text-white p-4 py-8 flex flex-col fixed md:relative z-50 transition-transform duration-300 ${
                    isMobileMenuOpen ? "translate-x-0 backdrop-blur-[100px] bg-[var(--white-transparent)]/40 left-0" : "-translate-x-full md:translate-x-0"
                }`}
            >
                {/* Close Button (X) */}
                <button
                    className="absolute top-4 right-4 button !border-none !mt-0 md:hidden text-white focus:outline-none"
                    onClick={toggleMobileMenu}
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
                {/* Logo */}
                <div className="flex flex-row items-center mb-8">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="32" viewBox="0 0 158 140" fill="none">
                        <path
                            d="M71.0001 0H69.6043L9.10434 61C-19.3957 88.5 24.6043 145 64.1043 108L117.604 54.5C122.104 50 126.604 55 123.604 58L42.1045 139.5H86.5001H88.0001L148.5 78.5C177 51 133 -5.5 93.5001 31.5L40 85C35.5001 89.5 31.0001 84.5 34 81.5L115.5 0H71.0001Z"
                            fill="white"
                        />
                    </svg>
                    <p className="text-white ml-2 text-[18px]">SkenderPay</p>
                </div>
                {/* Sidebar items */}
                <div className="sidebar-items pt-8 flex-grow">
                    {navHeader.map((item, index) => (
                        <Link
                            to={item.to}
                            key={index}
                            className={`navbar-button flex items-center px-6 py-4 mb-2 rounded-[20px] ${
                                location.pathname === item.to
                                    ? "bg-[var(--green-colour)] text-[var(--background-colour)]"
                                    : "hover:bg-gray-700 text-white"
                            }`}
                        >
                            <span className="w-6 h-6 mr-3">{item.imgSrc}</span>
                            <span
                                className={`${
                                    location.pathname === item.to
                                        ? "text-[var(--background-colour)]"
                                        : "text-white"
                                }`}
                            >
                                {item.text}
                            </span>
                        </Link>
                    ))}
                </div>
                {/* Sidebar footer */}
                <div className="sidebar-items relative w-auto bottom-0">
                    {navFooter.map((item, index) => (
                        <Link
                            to={item.to}
                            key={index}
                            className={`navbar-button flex items-center px-6 py-4 mb-2 rounded-[20px] ${
                                location.pathname === item.to
                                    ? "bg-[var(--green-colour)] text-[var(--background-colour)]"
                                    : "hover:bg-gray-700 text-white"
                            }`}
                        >
                            <span className="w-6 h-6 mr-3">{item.imgSrc}</span>
                            <span
                                className={`${
                                    location.pathname === item.to
                                        ? "text-[var(--background-colour)]"
                                        : "text-white"
                                }`}
                            >
                                {item.text}
                            </span>
                        </Link>
                    ))}
                </div>
            </div>

            {/* Overlay for mobile menu */}
            {isMobileMenuOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
                    onClick={toggleMobileMenu}
                />
            )}
        </div>
    );
};

export default Sidebar;