import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import styles from "./SignupPage.module.css";
import logo from "../../assets/Logo.png";
import middleImg from "../../assets/MiddleImg.png";
import { useDispatch } from "react-redux";
import { loginAction } from "../../store/login-slice";

const Input = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  required = true,
}) => (
  <div className={styles.inputGroup}>
    <label className={styles.label}>
      {label} {required && <span style={{ color: "#ef4444" }}>*</span>}
    </label>
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={`${styles.input} ${error ? styles.inputError : ""}`}
      required={required}
    />
    {error && <span className={styles.errorText}>{error}</span>}
  </div>
);

const PasswordInput = ({
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  isPasswordVisible,
  togglePasswordVisibility,
  required = true,
}) => (
  <div className={styles.inputGroup}>
    <label className={styles.label}>
      {label} {required && <span style={{ color: "#ef4444" }}>*</span>}
    </label>
    <div className={styles.passwordContainer}>
      <input
        type={isPasswordVisible ? "text" : "password"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`${styles.input} ${error ? styles.inputError : ""}`}
        required={required}
      />
      <button
        type="button"
        className={styles.passwordToggle}
        onClick={togglePasswordVisibility}
        aria-label={isPasswordVisible ? "Hide password" : "Show password"}
      >
        {isPasswordVisible ? <EyeOff size={20} /> : <Eye size={20} />}
      </button>
    </div>
    {error && <span className={styles.errorText}>{error}</span>}
  </div>
);

const LeftPanel = () => (
  <div className={styles.leftPanel}>
    <div className={styles.logo}>
      <img src={logo} alt="Logo" />
      <span className={styles.logoText}>Skenderpay</span>
    </div>
    <div className={styles.dashboardPreview}>
      <img src={middleImg} alt="Dashboard Preview" />
    </div>
    <div className={styles.content}>
      <h1 className={styles.title}>Speedy, Easy and Fast</h1>
      <p className={styles.description}>
        Skenderpay helps you set saving goals, earn cash back offers, and get
        paychecks up to two days early.
      </p>
    </div>
    <div className={styles.decorativeDots}></div>
  </div>
);

const SignupPage = ({ logo, middleImg }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
        if (!value.trim()) return "First name is required";
        if (!/^[A-Za-z\s]{2,30}$/.test(value))
          return "First name should be 2-30 characters long and contain only letters";
        return "";

      case "lastName":
        if (!value.trim()) return "Last name is required";
        if (!/^[A-Za-z\s]{2,30}$/.test(value))
          return "Last name should be 2-30 characters long and contain only letters";
        return "";

      case "email":
        if (!value.trim()) return "Email is required";
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))
          return "Please enter a valid email address";
        return "";

      case "password":
        if (!value) return "Password is required";
        if (value.length < 8)
          return "Password must be at least 8 characters long";
        if (!/(?=.*[a-z])/.test(value))
          return "Password must contain at least one lowercase letter";
        if (!/(?=.*[A-Z])/.test(value))
          return "Password must contain at least one uppercase letter";
        if (!/(?=.*\d)/.test(value))
          return "Password must contain at least one number";
        return "";

      case "confirmPassword":
        if (!value) return "Please confirm your password";
        if (value !== formData.password) return "Passwords do not match";
        return "";

      default:
        return "";
    }
  };

  const handleChange = (field) => (e) => {
    const newValue = e.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: newValue,
    }));

    if (touched[field]) {
      setErrors((prev) => ({
        ...prev,
        [field]: validateField(field, newValue),
      }));
    }
  };

  const handleBlur = (field) => () => {
    setTouched((prev) => ({
      ...prev,
      [field]: true,
    }));
    setErrors((prev) => ({
      ...prev,
      [field]: validateField(field, formData[field]),
    }));
  };

  useEffect(() => {
    const validateForm = () => {
      const newErrors = {};
      Object.keys(formData).forEach((field) => {
        const error = validateField(field, formData[field]);
        if (error) newErrors[field] = error;
      });
      setErrors(newErrors);
      setIsFormValid(Object.keys(newErrors).length === 0);
    };

    validateForm();
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Mark all fields as touched
    const allTouched = Object.keys(formData).reduce(
      (acc, field) => ({ ...acc, [field]: true }),
      {}
    );
    setTouched(allTouched);

    // Validate all fields
    const formErrors = {};
    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) formErrors[field] = error;
    });

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      try {
        const data = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
        };

        const response = await fetch("https://skenderpay.xyz:8443/", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        const parsedResponse = await response.json();

        if (parsedResponse.message === "success") {
          // Reset form
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
          });

          // Reset touched states
          setTouched({});
          dispatch(
            loginAction.userLogin({
              userId: parsedResponse.userId,
              firstName: parsedResponse.firstName,
            })
          );

          navigate("/profile");
        } else {
          // Handle API error
          setErrors((prev) => ({
            ...prev,
            submit:
              "Signup failed: " +
              (parsedResponse.error || "Please try again later"),
          }));
          console.error("API Error:", parsedResponse);
        }
      } catch (error) {
        console.error("Signup failed:", error);
        setErrors((prev) => ({
          ...prev,
          submit: "Signup failed: Unable to connect to the server",
        }));
      }
    }
  };

  return (
    <div className={styles.page}>
      <LeftPanel logo={logo} middleImg={middleImg} />

      <div className={styles.rightPanel}>
        {errors.submit && (
          <div className={styles.errorText} style={{ marginTop: "1rem" }}>
            {errors.submit}
          </div>
        )}
        <form onSubmit={handleSubmit} className={styles.form}>
          <h2 className={styles.formTitle}>
            Safeguarding Your Digital Wealth with an Extra Layer of Protection
          </h2>

          <div className={styles.formFields}>
            <Input
              label="First Name"
              type="text"
              placeholder="Enter your first name"
              value={formData.firstName}
              onChange={handleChange("firstName")}
              onBlur={handleBlur("firstName")}
              error={touched.firstName ? errors.firstName : ""}
            />
            <Input
              label="Last Name"
              type="text"
              placeholder="Enter your last name"
              value={formData.lastName}
              onChange={handleChange("lastName")}
              onBlur={handleBlur("lastName")}
              error={touched.lastName ? errors.lastName : ""}
            />
            <Input
              label="Email"
              type="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleChange("email")}
              onBlur={handleBlur("email")}
              error={touched.email ? errors.email : ""}
            />
            <PasswordInput
              label="Password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange("password")}
              onBlur={handleBlur("password")}
              error={touched.password ? errors.password : ""}
              isPasswordVisible={isPasswordVisible}
              togglePasswordVisibility={() =>
                setIsPasswordVisible(!isPasswordVisible)
              }
            />
            <PasswordInput
              label="Confirm Password"
              placeholder="Confirm your password"
              value={formData.confirmPassword}
              onChange={handleChange("confirmPassword")}
              onBlur={handleBlur("confirmPassword")}
              error={touched.confirmPassword ? errors.confirmPassword : ""}
              isPasswordVisible={isConfirmPasswordVisible}
              togglePasswordVisibility={() =>
                setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
              }
            />
          </div>

          {errors.submit && (
            <div className={styles.errorText} style={{ marginTop: "1rem" }}>
              {errors.submit}
            </div>
          )}

          <button
            type="submit"
            className={styles.submitButton}
            style={{ marginTop: "1.5rem", opacity: isFormValid ? 1 : 0.7 }}
            disabled={!isFormValid}
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;
