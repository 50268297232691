import React from "react";
import module from "./style.module.css";
import Header from "./layout/header";
import Footer from "./layout/footer";
import heroImage from '../../assets/landing/ICO-hero-image.png';  // adjust path as necessary

const Landing = () => {
    return (
        <div className={`relative ${module.container}`}>
            {/* Circles */}
            <div className="bg-[--green-colour] w-[700px] h-[700px] top-40 right-0 absolute rounded-full z-0"></div>
            <div className="bg-[--orange-colour] w-[400px] h-[400px] top-[60%] left-10 absolute rounded-full z-0"></div>
            <div className="bg-[--white-transparent] w-[600px] h-[600px] top-[20%] left-10 absolute rounded-full z-0"></div>

            {/* Blurred overlay */}
            <div className="absolute inset-0 backdrop-blur-[60px] bg-black/40 flex justify-center items-center z-10"></div>

            {/* Header */}
            <Header />

            {/* Content Wrapper - Make it Scrollable */}
            <div className="relative w-[100vw] p-4 md:p-0 flex flex-col items-center z-20">
                {/* Section 1 */}
                <div className={`w-full ${module.maxWContainer} relative h-[100vh] flex flex-row items-center justify-center`}>
                    <div className="h-[100vh] max-w-[100%] flex flex-col justify-center">
                        <div className="w-full md:max-w-[80%]">
                            <h1 className="text-white text-[60px] leading-[1.2]">
                                Digital payments.<br/>
                                <span className="font-['Tangerine'] font-[400] text-[120px]">Simplified.</span>
                            </h1>
                            <p className="text-white text-[20px] max-w-[80%] mt-8 mb-6">
                                With our web3 AI platform, we envision a groundbreaking transformation in the blockchain landscape, and we are excited to unveil it to you soon. We are dedicatedly striving to guarantee the security, reliability, and user-friendliness of our digital currency as we prepare for launch.
                            </p>
                            <button className={`${module.button} bg-[--white-transparent] px-20 py-2`}>Start now
                            </button>
                        </div>
                    </div>
                    <div className="absolute right-0">
                        <img src={heroImage}/>
                    </div>
                </div>

                {/* Section 2 */}
                <div className="w-full h-[100vh] flex flex-col items-center justify-center">
                    <p className="text-caption text-white mb-4xs">Crafted to perfection, built for
                        life.</p>
                    <h1 className="text-[120px] leading-[1.1] font-['Tangerine'] font-[400] md:text-h1 text-white mb-4">How it works</h1>
                    <p className="text-lg max-w-2xl text-center text-white">
                        Our innovative solution promises to revolutionize transactions offering unparalleled security,
                        efficiency, and transparency.
                    </p>
                </div>

                {/* Section 3 */}
                <div className="w-full h-[100vh] flex flex-col items-center justify-center">
                    <p className="text-caption text-white mb-4xs">Why Us</p>
                    <h1 className="text-[120px] leading-[1.1] font-['Tangerine'] font-[400] md:text-h1 text-white mb-4">Latest ICO statistics</h1>
                    <p className="text-lg max-w-2xl text-center text-white">
                        Our innovative solution promises to revolutionize transactions offering unparalleled security,
                        efficiency, and transparency.
                    </p>
                </div>

                {/* Section 4 */}
                <div className="w-full h-[100vh] flex flex-col items-center justify-center">
                    <p className="text-caption text-white mb-4xs">Why Us</p>
                    <h1 className="text-[120px] leading-[1.1] font-['Tangerine'] font-[400] md:text-h1 text-white mb-4">About Us</h1>
                    <p className="text-lg max-w-2xl text-center text-white">
                        Our innovative solution promises to revolutionize transactions offering unparalleled security,
                        efficiency, and transparency.
                    </p>
                </div>

                {/* Section 5 */}
                <div className="w-full h-[100vh] flex flex-col items-center justify-center">
                    <p className="text-caption text-white mb-4xs">Why Us</p>
                    <h1 className="text-[120px] leading-[1.1] font-['Tangerine'] font-[400] md:text-h1 text-white mb-4">Join the waiting list</h1>
                    <p className="text-lg max-w-2xl text-center text-white">
                        Our innovative solution promises to revolutionize transactions offering unparalleled security,
                        efficiency, and transparency.
                    </p>
                </div>

                {/* Section 6 */}
                <div className="w-full h-[100vh] flex flex-col items-center justify-center">
                    <p className="text-caption text-white mb-4xs">Why Us</p>
                    <h1 className="text-[120px] leading-[1.1] font-['Tangerine'] font-[400] md:text-h1 text-white mb-4">FAQ</h1>
                    <p className="text-lg max-w-2xl text-center text-white">
                        Our innovative solution promises to revolutionize transactions offering unparalleled security,
                        efficiency, and transparency.
                    </p>
                </div>

                {/* Footer */}
                <Footer/>
            </div>
        </div>
    );
}

export default Landing;