import React, { useState } from 'react';
import '../../../style.css'
import { useTable, useSortBy } from 'react-table';
import Layout from "../Layout/Layout";

const Marketplace = (props) => {

    return (
        <Layout>

        </Layout>
    )
}

export default Marketplace;
