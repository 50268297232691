import React, { useEffect, useState, useRef } from "react";
import module from "./style.module.css";

const Footer = () => {

    return (
        <footer className="text-white bg-[--background-colour] w-[100vw] py-12 px-4 md:px-0">
            <div className={`w-full ${module.maxWContainer} mx-auto animate-on-scroll translate-y-10 transition-all duration-700`}>
                <div className="flex flex-col md:flex-row justify-between">

                    <div className="mb-8 md:mb-0">
                        {/* Logo */}
                        <div className="flex flex-row items-center mb-8">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="62" viewBox="0 0 158 140"
                                 fill="white">
                                <path
                                    d="M71.0001 0H69.6043L9.10434 61C-19.3957 88.5 24.6043 145 64.1043 108L117.604 54.5C122.104 50 126.604 55 123.604 58L42.1045 139.5H86.5001H88.0001L148.5 78.5C177 51 133 -5.5 93.5001 31.5L40 85C35.5001 89.5 31.0001 84.5 34 81.5L115.5 0H71.0001Z"
                                    fill="white"/>
                            </svg>
                            <p className="text-white ml-3 text-[28px]">SkenderPay</p>
                        </div>
                    </div>


                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="flex flex-col items-start">
                            <h3 className="text-white font-semibold mb-4">Section title</h3>
                            <ul className="space-y-2 text-white">
                                <li><a href=""
                                       className="hover:underline">Section</a></li>
                                <li><a href="" className="hover:underline">Section</a></li>
                                <li><a href="" className="hover:underline">Section</a>
                                </li>
                                <li><a href="" className="hover:underline">Section</a></li>
                                <li><a href="" className="hover:underline">Section</a></li>
                            </ul>
                        </div>
                        <div className="flex flex-col items-start">
                            <h3 className="text-white font-semibold mb-4">Section title</h3>
                            <ul className="space-y-2 text-white">
                                <li><a href=""
                                       className="hover:underline">Section</a></li>
                                <li><a href="" className="hover:underline">Section</a></li>
                                <li><a href="" className="hover:underline">Section</a>
                                </li>
                                <li><a href="" className="hover:underline">Section</a></li>
                                <li><a href="" className="hover:underline">Section</a></li>
                            </ul>
                        </div>
                        <div className="flex flex-col items-start">
                            <h3 className="text-white font-semibold mb-4">Section title</h3>
                            <ul className="space-y-2 text-white">
                                <li><a href=""
                                       className="hover:underline">Section</a></li>
                                <li><a href="" className="hover:underline">Section</a></li>
                                <li><a href="" className="hover:underline">Section</a>
                                </li>
                                <li><a href="" className="hover:underline">Section</a></li>
                                <li><a href="" className="hover:underline">Section</a></li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div
                    className="border-t border-gray-300 mt-10 pt-6 pb-6 text-sm text-white flex flex-col sm:flex-row justify-between">
                    <p>&copy; 2024 SkenderPay. All rights reserved</p>
                    <div className="flex flex-wrap gap-4 mt-4 sm:mt-0">
                        <a href="" className="hover:underline">Terms of Service</a>
                        <a href="" className="hover:underline">Policy service</a>
                        <a href="" className="hover:underline">Cookie Policy</a>
                    </div>
                </div>
            </div>
        </footer>

    );
};

export default Footer;